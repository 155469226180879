// src/js/components/CategoriesSection.js
export class CategoriesManager {
  constructor(container) {
    this.container = container;
    this.init();
  }

  async init() {
    try {
      this.showLoading();
      const categories = await this.getPopularCategories();
      this.renderCategories(categories);
    } catch (error) {
      console.error('Erreur lors du chargement des catégories:', error);
      this.showError();
    }
  }

  async getPopularCategories() {
    // Récupérer toutes les images
    const response = await fetch('https://restapi.fr/api/ingallery');
    const images = await response.json();

    // Transformer en tableau si on reçoit un seul objet
    const imagesArray = Array.isArray(images) ? images : [images];

    // Extraire et compter les tags
    const tagCounts = imagesArray.reduce((acc, image) => {
      if (image.tags && Array.isArray(image.tags)) {
        image.tags.forEach((tag) => {
          acc[tag] = (acc[tag] || 0) + 1;
        });
      }
      return acc;
    }, {});

    // Convertir en tableau et trier par popularité
    const sortedCategories = Object.entries(tagCounts)
      .map(([tag, count]) => ({ tag, count }))
      .sort((a, b) => b.count - a.count)
      .slice(0, 6); // Prendre les 6 plus populaires

    return sortedCategories;
  }

  renderCategories(categories) {
    if (!this.container) return;

    // Créer le HTML des catégories
    this.container.innerHTML = `
            <div class="section-header">
                <h2>Catégories populaires</h2>
            </div>
            <div class="categories-grid">
                ${categories
                  .map(
                    (category) => `
                    <a href="/category/${category.tag}" class="category-card">
                        <div class="category-content">
                            <h3>${category.tag}</h3>
                            <span class="category-count">${category.count} image${category.count > 1 ? 's' : ''}</span>
                        </div>
                    </a>
                `
                  )
                  .join('')}
            </div>
        `;
  }

  showLoading() {
    this.container.innerHTML = `
            <div class="loading-state">
                <div class="loading-spinner"></div>
                <p>Chargement des catégories...</p>
            </div>
        `;
  }

  showError() {
    this.container.innerHTML = `
            <div class="error-state">
                <p>Une erreur est survenue lors du chargement des catégories.</p>
                <button onclick="location.reload()">Réessayer</button>
            </div>
        `;
  }
}
