// src/js/components/Autocomplete.js
export class Autocomplete {
  constructor() {
    this.init();
    this.suggestions = [
      { type: 'tag', text: 'nature', count: '2.5k images' },
      { type: 'tag', text: 'urban', count: '1.8k images' },
      { type: 'tag', text: 'architecture', count: '1.2k images' },
      { type: 'category', text: 'Paysages', count: '5k images' },
      { type: 'category', text: 'Portraits', count: '3k images' },
      { type: 'photographer', text: 'Jean Dupont', count: '150 photos' },
    ];
  }

  init() {
    this.searchInput = document.querySelector('.search-input');
    this.createAutocompleteContainer();
    this.bindEvents();
  }

  createAutocompleteContainer() {
    const container = document.createElement('div');
    container.className = 'search-suggestions';
    this.searchInput.parentNode.appendChild(container);
    this.suggestionsContainer = container;
  }

  bindEvents() {
    // Gestion de la saisie
    this.searchInput.addEventListener('input', (e) => {
      const value = e.target.value.trim();
      if (value.length >= 2) {
        this.showSuggestions(value);
      } else {
        this.hideSuggestions();
      }
    });

    // Gestion du focus
    this.searchInput.addEventListener('focus', () => {
      if (this.searchInput.value.trim().length >= 2) {
        this.showSuggestions(this.searchInput.value);
      }
    });

    // Fermeture au clic à l'extérieur
    document.addEventListener('click', (e) => {
      if (!e.target.closest('.search-bar')) {
        this.hideSuggestions();
      }
    });

    // Navigation au clavier
    this.searchInput.addEventListener('keydown', (e) => {
      if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
        e.preventDefault();
        this.navigateSuggestions(e.key === 'ArrowDown' ? 1 : -1);
      }
      if (e.key === 'Enter') {
        this.selectCurrentSuggestion();
      }
      if (e.key === 'Escape') {
        this.hideSuggestions();
      }
    });
  }

  showSuggestions(query) {
    const filteredSuggestions = this.filterSuggestions(query);
    if (filteredSuggestions.length === 0) {
      this.hideSuggestions();
      return;
    }

    this.suggestionsContainer.innerHTML =
      this.createSuggestionsHTML(filteredSuggestions);
    this.suggestionsContainer.classList.add('active');

    // Gestion des clics sur les suggestions
    this.suggestionsContainer
      .querySelectorAll('.suggestion-item')
      .forEach((item) => {
        item.addEventListener('click', () => {
          this.searchInput.value = item.dataset.value;
          this.hideSuggestions();
        });
      });
  }

  filterSuggestions(query) {
    return this.suggestions.filter((suggestion) =>
      suggestion.text.toLowerCase().includes(query.toLowerCase())
    );
  }

  createSuggestionsHTML(suggestions) {
    const groupedSuggestions = this.groupSuggestions(suggestions);

    return Object.entries(groupedSuggestions)
      .map(
        ([type, items]) => `
            <div class="suggestions-section">
                <div class="suggestions-section-title">${type}</div>
                ${items
                  .map(
                    (item) => `
                    <div class="suggestion-item" data-value="${item.text}">
                        <div class="suggestion-icon">
                            ${this.getIconForType(type)}
                        </div>
                        <div class="suggestion-content">
                            <div class="suggestion-title">${item.text}</div>
                            <div class="suggestion-subtitle">${item.count}</div>
                        </div>
                    </div>
                `
                  )
                  .join('')}
            </div>
        `
      )
      .join('');
  }

  groupSuggestions(suggestions) {
    return suggestions.reduce((acc, suggestion) => {
      const type =
        suggestion.type.charAt(0).toUpperCase() + suggestion.type.slice(1);
      if (!acc[type]) acc[type] = [];
      acc[type].push(suggestion);
      return acc;
    }, {});
  }

  getIconForType(type) {
    const icons = {
      Tag: '🏷️',
      Category: '📁',
      Photographer: '📸',
    };
    return icons[type] || '🔍';
  }

  hideSuggestions() {
    this.suggestionsContainer.classList.remove('active');
  }

  navigateSuggestions(direction) {
    const items =
      this.suggestionsContainer.querySelectorAll('.suggestion-item');
    const currentIndex = Array.from(items).findIndex((item) =>
      item.classList.contains('selected')
    );

    items.forEach((item) => item.classList.remove('selected'));

    let newIndex = currentIndex + direction;
    if (newIndex >= items.length) newIndex = 0;
    if (newIndex < 0) newIndex = items.length - 1;

    items[newIndex]?.classList.add('selected');
  }

  selectCurrentSuggestion() {
    const selectedItem = this.suggestionsContainer.querySelector(
      '.suggestion-item.selected'
    );
    if (selectedItem) {
      this.searchInput.value = selectedItem.dataset.value;
      this.hideSuggestions();
    }
  }
}
