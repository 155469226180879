// src/js/components/ImageUploader.js
import { ApiService } from '../services/apiService.js';

export class ImageUploader {
  constructor(container) {
    if (!container) {
      console.error('Container element is required for ImageUploader');
      return;
    }

    this.container = container;
    this.apiService = new ApiService();
    this.selectedFile = null;
    // this.initializeUploader();
    this.render();
    this.bindEvents();
  }

  //   initializeUploader() {
  // Création de la zone de dépôt
  // this.createUploadZone();
  // Ajout du formulaire de détails
  // this.createUploadForm();
  //   }

  render() {
    // const uploadSection = document.querySelector('.upload-section');
    // if (!uploadSection) return;

    this.container.innerHTML = `
            <div class="upload-container">
                <div class="upload-dropzone" id="upload-dropzone">
                    <div class="dropzone-content">
                        <div class="upload-icon">📁</div>
                        <h3>Glissez et déposez vos images ici</h3>
                        <p>ou</p>
                        <label class="upload-button">
                            Parcourir
                            <input type="file" accept="image/*" multiple hidden>
                        </label>
                        <p class="upload-info">PNG, JPG, GIF jusqu'à 5 MB</p>
                    </div>
                </div>
                
                

                <div class="upload-preview" id="upload-preview"></div>
                
                <div class="upload-progress-container hidden">
                    <div class="upload-progress">
                        <div class="progress-bar"></div>
                    </div>
                    <div class="progress-info">
                        <span class="progress-percentage">0%</span>
                        <span class="progress-status">En attente...</span>
                    </div>
                </div>
                
                <form id="upload-form" class="upload-form hidden">
                    <h3>Détails de l'image</h3>
                    <div class="form-group">
                        <label for="title">Titre</label>
                        <input type="text" id="title" name="title" required>
                    </div>

                    <!-- Ajout du champ auteur(s) -->
                    <div class="form-group">
                        <label for="authors">Auteur(s)</label>
                        <input 
                            type="text" 
                            id="authors" 
                            name="authors" 
                            placeholder="Votre nom ou noms des collaborateurs (séparés par des virgules)"
                        >
                    </div>

                    <div class="form-group">
                        <label for="description">Description</label>
                        <textarea id="description" name="description" rows="3"></textarea>
                    </div>
                    <div class="form-group">
                        <label for="tags">Tags (séparés par des virgules)</label>
                        <input type="text" id="tags" name="tags">
                    </div>
                    <button type="submit" class="upload-submit">Téléverser</button>
                </form>
            </div>
        `;
  }

  //   createUploadForm() {
  // const formHTML = `
  // <form id="upload-form" class="upload-form hidden">
  // <h3>Détails de l'image</h3>
  // <div class="form-group">
  // <label for="title">Titre</label>
  // <input type="text" id="title" name="title" required>
  // </div>
  // <div class="form-group">
  // <label for="description">Description</label>
  // <textarea id="description" name="description" rows="3"></textarea>
  // </div>
  // <div class="form-group">
  // <label for="tags">Tags (séparés par des virgules)</label>
  // <input type="text" id="tags" name="tags">
  // </div>
  // <button type="submit" class="upload-submit">Téléverser</button>
  // </form>
  // `;

  // document
  //   .querySelector('.upload-container')
  //   .insertAdjacentHTML('beforeend', formHTML);
  //   }

  bindEvents() {
    const dropzone = document.getElementById('upload-dropzone');
    const fileInput = dropzone.querySelector('input[type="file"]');
    const form = document.getElementById('upload-form');

    // Gestion du glisser-déposer
    dropzone.addEventListener('dragover', (e) => {
      e.preventDefault();
      dropzone.classList.add('drag-over');
    });

    dropzone.addEventListener('dragleave', () => {
      dropzone.classList.remove('drag-over');
    });

    dropzone.addEventListener('drop', (e) => {
      e.preventDefault();
      dropzone.classList.remove('drag-over');
      this.handleFiles(e.dataTransfer.files);
    });

    // Gestion de la sélection de fichiers
    fileInput.addEventListener('change', (e) => {
      this.handleFiles(e.target.files);
    });

    // Gestion du formulaire
    form.addEventListener('submit', (e) => {
      e.preventDefault();
      this.submitForm(form);
    });
  }

  async handleFiles(files) {
    const validFiles = Array.from(files).filter((file) => {
      const validTypes = ['image/jpeg', 'image/png', 'image/gif'];
      const maxSize = 5 * 1024 * 1024; // 5 MB
      return validTypes.includes(file.type) && file.size <= maxSize;
    });

    if (validFiles.length === 0) {
      this.showError(
        'Veuillez sélectionner des images valides (JPG, PNG, GIF, max 5MB)'
      );
      return;
    }

    this.selectedFile = validFiles[0]; // Stocker le fichier sélectionné
    this.displayPreviews(validFiles);
    document.getElementById('upload-form').classList.remove('hidden');
  }

  displayPreviews(files) {
    const previewContainer = document.getElementById('upload-preview');
    previewContainer.innerHTML = '';

    files.forEach((file, index) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const preview = document.createElement('div');
        preview.className = 'preview-item';
        preview.innerHTML = `
                    <img src="${e.target.result}" alt="Aperçu">
                    <div class="preview-info">
                        <span class="preview-filename">${file.name}</span>
                        <span class="preview-size">${this.formatFileSize(file.size)}</span>
                    </div>
                    <button type="button" class="preview-remove" data-index="${index}">×</button>
                `;
        previewContainer.appendChild(preview);
      };
      reader.readAsDataURL(file);
    });
  }

  async convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  }

  async submitForm(form) {
    const progressContainer = document.querySelector(
      '.upload-progress-container'
    );
    const progressBar = progressContainer.querySelector('.progress-bar');
    const progressPercentage = progressContainer.querySelector(
      '.progress-percentage'
    );
    const progressStatus = progressContainer.querySelector('.progress-status');

    progressContainer.classList.remove('hidden');
    progressStatus.textContent = 'Téléversement en cours...';

    try {
      const formData = new FormData(form);

      // Vérifier si un fichier a été sélectionné
      if (!this.selectedFile) {
        throw new Error('Veuillez sélectionner une image valide');
      }

      // Préparation des données pour l'API
      const imageData = {
        title: formData.get('title'),
        description: formData.get('description'),
        tags: formData.get('tags')
          ? formData
              .get('tags')
              .split(',')
              .map((tag) => tag.trim())
              .filter(Boolean)
          : [],
        author: formData.get('authors')
          ? formData
              .get('authors')
              .split(',')
              .map((author) => author.trim())
              .filter(Boolean)
          : [],
      };

      // Conversion de l'image en Base64
      imageData.image = await this.convertToBase64(this.selectedFile);

      // Envoi à l'API
      const response = await this.apiService.uploadImage(imageData);
      console.log('Upload réussi:', response);

      // Mise à jour de la progression
      this.updateProgress(100, progressBar, progressPercentage);
      progressStatus.textContent = 'Téléversement terminé !';

      // Réinitialisation
      setTimeout(() => {
        progressContainer.classList.add('hidden');
        form.reset();
        document.getElementById('upload-preview').innerHTML = '';
        this.selectedFile = null; // Réinitialiser le fichier sélectionné
      }, 2000);
    } catch (error) {
      console.error('Erreur de téléversement:', error);
      progressStatus.textContent =
        error.message || 'Erreur lors du téléversement';
      progressStatus.classList.add('error');
    }
  }

  updateProgress(percentage, progressBar, progressText) {
    progressBar.style.width = `${percentage}%`;
    progressText.textContent = `${Math.round(percentage)}%`;
  }

  formatFileSize(bytes) {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  }

  showError(message) {
    const errorDiv = document.createElement('div');
    errorDiv.className = 'upload-error';
    errorDiv.textContent = message;
    document
      .querySelector('.upload-container')
      .insertAdjacentElement('afterbegin', errorDiv);
    setTimeout(() => errorDiv.remove(), 3000);
  }
}
