// src/js/utils/KeyboardShortcuts.js
export class KeyboardShortcutManager {
  constructor() {
    this.shortcuts = new Map();
    this.initializeDefaultShortcuts();
    this.bindEvents();
  }

  initializeDefaultShortcuts() {
    this.shortcuts.set('/', {
      description: 'Focaliser la barre de recherche',
      action: () => document.querySelector('.search-input')?.focus(),
    });

    this.shortcuts.set('Escape', {
      description: 'Fermer les suggestions/Vider la recherche',
      action: () => {
        const searchInput = document.querySelector('.search-input');
        if (searchInput?.value) {
          searchInput.value = '';
        }
      },
    });

    this.shortcuts.set('Alt+f', {
      description: 'Ouvrir les filtres',
      action: () => {
        const filterButton = document.querySelector('.filter-tag');
        filterButton?.click();
      },
    });

    this.shortcuts.set('Alt+h', {
      description: "Afficher l'aide des raccourcis",
      action: () => this.showShortcutsHelp(),
    });
  }

  bindEvents() {
    document.addEventListener('keydown', (e) => {
      // Ignore les raccourcis si on est dans un champ de texte (sauf pour Escape)
      if (e.target.matches('input, textarea') && e.key !== 'Escape') {
        return;
      }

      // Gestion des raccourcis avec modificateurs
      const key = e.altKey ? `Alt+${e.key.toLowerCase()}` : e.key;

      if (this.shortcuts.has(key)) {
        e.preventDefault();
        this.shortcuts.get(key).action();
      }
    });
  }

  showShortcutsHelp() {
    const helpDialog = document.createElement('div');
    helpDialog.className = 'shortcuts-help';

    helpDialog.innerHTML = `
            <div class="shortcuts-help-content">
                <h2>Raccourcis clavier</h2>
                <div class="shortcuts-list">
                    ${Array.from(this.shortcuts)
                      .map(
                        ([key, { description }]) => `
                        <div class="shortcut-item">
                            <kbd>${key}</kbd>
                            <span>${description}</span>
                        </div>
                    `
                      )
                      .join('')}
                </div>
                <button class="close-help">Fermer</button>
            </div>
        `;

    document.body.appendChild(helpDialog);

    // Gestion de la fermeture
    const closeBtn = helpDialog.querySelector('.close-help');
    closeBtn.addEventListener('click', () => helpDialog.remove());

    helpDialog.addEventListener('click', (e) => {
      if (e.target === helpDialog) {
        helpDialog.remove();
      }
    });
  }
}
