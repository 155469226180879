// src/js/components/Footer.js
export const createFooter = () => {
  const currentYear = new Date().getFullYear();

  return `
        <footer class="main-footer">
            <div class="container">
                <div class="footer-content">
                    <!-- Section Logo et Description -->
                    <div class="footer-branding">
                        <a href="/" class="footer-logo" data-route="/">
                            <h3>ImageGallery</h3>
                        </a>
                        <p class="footer-description">
                            Une plateforme de partage d'images pour les passionnés de photographie. Découvrez, partagez et inspirez.
                        </p>
                        <div class="footer-stats">
                            <div class="stat-item">
                                <span class="stat-number">1000+</span>
                                <span class="stat-label">Images</span>
                            </div>
                            <div class="stat-item">
                                <span class="stat-number">500+</span>
                                <span class="stat-label">Utilisateurs</span>
                            </div>
                        </div>
                    </div>

                    <!-- Navigation et Informations -->
                    <div class="footer-navigation">
                        <!-- Navigation existante -->
                        <div class="footer-section">
                            <h4>Navigation</h4>
                            <ul>
                                <li><a href="/" data-route="/">Accueil</a></li>
                                <li><a href="/explorer" data-route="/explorer">Explorer</a></li>
                                <li><a href="/upload" data-route="/upload">Upload</a></li>
                                <li><a href="/categories" data-route="/categories">Catégories</a></li>
                            </ul>
                        </div>

                        <!-- Catégories -->
                        <!--<div class="footer-section">-->
                        <!--    <h4>Catégories Populaires</h4>-->
                        <!--    <ul>-->
                        <!--        <li><a href="/category/nature">Nature</a></li>-->
                        <!--        <li><a href="/category/urban">Urban</a></li>-->
                        <!--        <li><a href="/category/portrait">Portrait</a></li>-->
                        <!--        <li><a href="/category/art">Art Digital</a></li>-->
                        <!--    </ul>-->
                        <!--</div>-->

                        <!-- Support -->
                        <div class="footer-section">
                            <h4>Support</h4>
                            <ul>
                                <li><a href="/faq" data-route="/faq">FAQ</a></li>
                                <li><a href="/contact" data-route="/contact">Contact</a></li>
                                <!--<li><a href="/help" data-route="/help">Centre d'aide</a></li>-->
                                <!--<li><a href="/guidelines" data-route="/guidelines">Guidelines</a></li>-->
                            </ul>
                        </div>

                        <!-- Légal -->
                        <div class="footer-section">
                            <h4>Informations Légales</h4>
                            <ul>
                                <li><a href="/privacy" data-route="/privacy">Confidentialité</a></li>
                                <li><a href="/terms" data-route="/terms">Conditions d'utilisation</a></li>
                                <li><a href="/cookies" data-route="/cookies">Cookies</a></li>
                                <li><a href="/licenses" data-route="/licenses">Licences</a></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <!-- Section Communauté -->
                <div class="footer-community">
                    <h4>Rejoignez notre communauté</h4>
                    <p>Partagez vos meilleures photos et connectez-vous avec d'autres photographes</p>
                    <a href="/register" class="community-button">S'inscrire gratuitement</a>
                </div>

                <!-- Barre de Copyright -->
                <div class="footer-bottom">
                    <p class="copyright">
                        © ${currentYear} ImageGallery. Tous droits réservés.
                    </p>
                    <div class="footer-links">
                        <a href="/about">À propos</a>
                        <a href="/legal-notices" data-route="/legal-notices">Mentions Légales</a>
                    </div>
                </div>
            </div>
        </footer>
    `;
};
