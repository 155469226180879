// src/js/pages/Categories.js
import { CategoryGrid } from '../components/CategoryGrid.js';
import { createFooter } from '../components/Footer.js';
import { createHeader } from '../components/Header.js';

export const renderCategoriesPage = () => {
  const app = document.getElementById('app');

  app.innerHTML = `
        ${createHeader()}
        <main class="categories-page">
            <div class="container">
                <div class="page-header">
                    <h1>Catégories</h1>
                    <p>Explorez nos images par catégories</p>
                </div>
                <div id="categories-grid"></div>
            </div>
        </main>
        ${createFooter()}
    `;

  // Initialiser la grille de catégories
  const categoriesContainer = document.getElementById('categories-grid');
  new CategoryGrid(categoriesContainer);
};
