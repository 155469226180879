// src/js/pages/Home.js
import { CategoriesManager } from '../components/CategoriesSection.js';
import { createFooter } from '../components/Footer.js';
import { createHeader } from '../components/Header.js';
import { RecentUploadsManager } from '../components/RecentUploads.js';
import { createSearchSection } from '../components/SearchSection.js';

export const renderHomePage = async () => {
  const app = document.getElementById('app');

  // Structure de base avec nouvelles sections
  app.innerHTML = `
        ${createHeader()}
        <main>
            ${createSearchSection()}
            
            <!-- Catégories populaires -->
            
            
            <section class="categories-section">
                <div class="container">
                    <div id="categories-container"></div>
                </div>
            </section>


            
            <!-- Section des derniers ajouts -->
            <section class="recent-section">
                <div class="container">
                    <div class="section-header">
                      <h2>Derniers Ajouts</h2>
                      <a href="/explorer" class="see-all" data-route="/explorer">Voir plus</a>
                    </div>
                    <div class="recent-uploads" id="recent-uploads"></div>
                </div>
            </section>

            
        </main>
        ${createFooter()}
    `;

  // Initialiser les catégories
  const categoriesContainer = document.getElementById('categories-container');
  if (categoriesContainer) {
    new CategoriesManager(categoriesContainer);
  }

  // Attendre que le DOM soit mis à jour
  requestAnimationFrame(() => {
    const recentUploadsContainer = document.getElementById('recent-uploads');
    if (recentUploadsContainer) {
      new RecentUploadsManager(recentUploadsContainer);
    }
  });
};
