// src/js/pages/Terms.js
import { createFooter } from '../components/Footer.js';
import { createHeader } from '../components/Header.js';

export const renderContactPage = () => {
  const app = document.getElementById('app');

  app.innerHTML = `
        ${createHeader()}
        <main class="legal-page contact-page">
            <div class="container">
                            <div class="page-header">
                <h1>Contactez-nous</h1>
                <p>Vous avez des questions ou des suggestions ? Remplissez le formulaire ci-dessous pour nous contacter.</p>
            </div>

            <!-- Formulaire de contact -->
            <section class="contact-form">
                <h2>Formulaire de contact</h2>
                <form action="/submit-contact" method="POST">
                    <label for="name">Nom :</label>
                    <input type="text" id="name" name="name" placeholder="Votre nom" required>

                    <label for="email">Email :</label>
                    <input type="email" id="email" name="email" placeholder="Votre email" required>

                    <label for="subject">Sujet :</label>
                    <input type="text" id="subject" name="subject" placeholder="Sujet de votre message" required>

                    <label for="message">Message :</label>
                    <textarea id="message" name="message" rows="5" placeholder="Votre message..." required></textarea>

                    <button type="submit">Envoyer</button>
                </form>
            </section>

            <!-- Coordonnées -->
            <section class="contact-details">
                <h2>Nos coordonnées</h2>
                <p><strong>Email :</strong> contact@gallery.com</p>
                <p><strong>Téléphone :</strong> +33 1 23 45 67 89</p>
                <p><strong>Adresse :</strong> 123 Rue de la Galerie, 75000 Paris, France</p>
            </section>

            </div>
        </main>
        ${createFooter()}
    `;
};
