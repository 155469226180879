// src/js/components/FilterSection.js
export class FilterSection {
  constructor(container, onFilterChange) {
    this.container = container;
    this.onFilterChange = onFilterChange;
    this.currentFilters = {
      search: '',
      sort: 'recent',
      category: 'all',
    };
    this.init();
  }

  init() {
    this.render();
    this.bindEvents();
  }

  render() {
    this.container.innerHTML = `
            <div class="filter-bar">
                <div class="search-bar">
                    <input 
                        type="text" 
                        placeholder="Rechercher..." 
                        class="search-input"
                        value="${this.currentFilters.search}"
                    >
                    <button class="search-button">Rechercher</button>
                </div>
                
                <div class="filter-options">
                    <select class="sort-select" value="${this.currentFilters.sort}">
                        <option value="recent">Plus récents</option>
                        <option value="oldest">Plus anciens</option>
                        <option value="title">Titre A-Z</option>
                        <option value="title-desc">Titre Z-A</option>
                    </select>

                    <div class="filter-tags">
                        <button class="filter-tag ${this.currentFilters.category === 'all' ? 'active' : ''}" 
                                data-category="all">Toutes</button>
                        <button class="filter-tag ${this.currentFilters.category === 'nature' ? 'active' : ''}" 
                                data-category="nature">Nature</button>
                        <button class="filter-tag ${this.currentFilters.category === 'urban' ? 'active' : ''}" 
                                data-category="urban">Urban</button>
                        <button class="filter-tag ${this.currentFilters.category === 'portrait' ? 'active' : ''}" 
                                data-category="portrait">Portrait</button>
                    </div>
                </div>
            </div>
        `;
  }

  bindEvents() {
    // Ajouter les gestionnaires d'événements pour les filtres
    const searchInput = this.container.querySelector('.search-input');
    const searchButton = this.container.querySelector('.search-button');
    const sortSelect = this.container.querySelector('.sort-select');
    const filterTags = this.container.querySelectorAll('.filter-tag');

    // Recherche
    searchButton.addEventListener('click', () => this.handleSearch());
    searchInput.addEventListener('keypress', (e) => {
      if (e.key === 'Enter') this.handleSearch();
    });

    // Tri
    sortSelect.addEventListener('change', (e) => {
      this.currentFilters.sort = e.target.value;
      this.applyFilters();
    });

    // Catégories
    filterTags.forEach((tag) => {
      tag.addEventListener('click', () => {
        // Retirer la classe active de tous les tags
        filterTags.forEach((t) => t.classList.remove('active'));
        // Ajouter la classe active au tag cliqué
        tag.classList.add('active');
        this.currentFilters.category = tag.dataset.category;
        this.applyFilters();
      });
    });
  }

  handleSearch() {
    const searchInput = this.container.querySelector('.search-input');
    this.currentFilters.search = searchInput.value.trim();
    this.applyFilters();
  }

  applyFilters() {
    if (this.onFilterChange) {
      this.onFilterChange(this.currentFilters);
    }
  }
}
