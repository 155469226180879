// src/js/services/apiService.js

const API_BASE_URL = 'https://restapi.fr/api';
const COLLECTION_NAME = 'ingallery'; // Collection pour stocker les images

export class ApiService {
  constructor() {
    this.baseUrl = `${API_BASE_URL}/${COLLECTION_NAME}`;
  }

  // Téléverser une nouvelle image
  async uploadImage(imageData) {
    try {
      // Convertir l'image en Base64
      // const base64Image = await this.convertToBase64(imageData.file);

      // Créer l'objet à envoyer
      // const data = {
      // title: imageData.title,
      // description: imageData.description,
      // tags: imageData.tags,
      // image: base64Image,
      // author: imageData.author,
      // uploadDate: new Date().toISOString(),
      // };

      // const formData = new FormData();
      // formData.append('image', imageData.file);
      // formData.append('title', imageData.title);
      // formData.append('description', imageData.description);
      // formData.append('tags', JSON.stringify(imageData.tags));
      // formData.append('author', imageData.author);
      // formData.append('uploadDate', new Date().toISOString());

      const response = await fetch(this.baseUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(imageData), //formData,
      });

      if (!response.ok) {
        throw new Error("Erreur lors du téléversement de l'image");
      }

      return await response.json();
    } catch (error) {
      console.error("Erreur d'upload:", error);
      throw error;
    }
  }

  // Méthode pour convertir un fichier en Base64
  convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  // Récupérer toutes les images avec pagination
  async getImages(page = 1, limit = 20, filters = {}) {
    try {
      let url = `${this.baseUrl}?page=${page}&limit=${limit}`;

      // Ajout des filtres à l'URL
      Object.entries(filters).forEach(([key, value]) => {
        if (value) {
          url += `&${key}=${encodeURIComponent(value)}`;
        }
      });

      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Erreur lors de la récupération des images');
      }

      const data = await response.json();
      return {
        images: data.data || data, // Selon la structure de réponse de l'API
        totalCount: data.totalCount,
        currentPage: page,
      };
    } catch (error) {
      console.error('Erreur de récupération:', error);
      throw error;
    }
  }

  // Récupérer une image spécifique
  async getImage(id) {
    try {
      const response = await fetch(`${this.baseUrl}/${id}`);
      if (!response.ok) {
        throw new Error('Image non trouvée');
      }
      return await response.json();
    } catch (error) {
      console.error('Erreur de récupération:', error);
      throw error;
    }
  }

  // Mettre à jour les informations d'une image
  async updateImage(id, updateData) {
    try {
      const response = await fetch(`${this.baseUrl}/${id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updateData),
      });

      if (!response.ok) {
        throw new Error("Erreur lors de la mise à jour de l'image");
      }

      return await response.json();
    } catch (error) {
      console.error('Erreur de mise à jour:', error);
      throw error;
    }
  }

  // Supprimer une image
  async deleteImage(id) {
    try {
      const response = await fetch(`${this.baseUrl}/${id}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error("Erreur lors de la suppression de l'image");
      }

      return true;
    } catch (error) {
      console.error('Erreur de suppression:', error);
      throw error;
    }
  }

  // Rechercher des images
  async searchImages(query, page = 1, limit = 20) {
    try {
      const url = `${this.baseUrl}?search=${encodeURIComponent(query)}&page=${page}&limit=${limit}`;
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error("Erreur lors de la recherche d'images");
      }

      return await response.json();
    } catch (error) {
      console.error('Erreur de recherche:', error);
      throw error;
    }
  }
}
