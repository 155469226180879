// src/js/components/RecentUploads.js
import { ImageModal } from './ImageModal.js';

export const createRecentUploads = () => {
  return `
        <div class="recent-uploads" id="recent-uploads">
            <div class="gallery-grid">
                <!-- Les images seront insérées ici dynamiquement -->
            </div>
        </div>
    `;
};

export class RecentUploadsManager {
  constructor(container) {
    if (!container) {
      throw new Error('Container element is required');
    }
    this.container = container;
    this.modal = new ImageModal();
    this.setupContainer();
    this.loadImages();
  }

  setupContainer() {
    // Créer la structure de base
    this.container.innerHTML = `
            <div class="gallery-grid">
                <div class="loading-state">
                    <div class="loading-spinner"></div>
                    <p>Chargement des dernières images...</p>
                </div>
            </div>
        `;
    this.grid = this.container.querySelector('.gallery-grid');
  }

  async loadImages() {
    try {
      const images = await this.fetchRecentImages();
      this.renderImages(images);
    } catch (error) {
      console.error('Erreur lors du chargement des images récentes:', error);
      this.showError();
    }
  }

  async fetchRecentImages() {
    // Utilisez l'URL de votre API RestAPI.fr
    const response = await fetch(
      'https://restapi.fr/api/ingallery?limit=12&sort=createdAt:desc'
    );
    if (!response.ok) {
      throw new Error('Erreur lors de la récupération des images');
    }
    return await response.json();
  }

  renderImages(images) {
    // S'assurer que images est un tableau
    const imagesArray = Array.isArray(images) ? images : [images];

    this.grid.innerHTML = imagesArray
      .map(
        (image) => `
            <article class="gallery-item fade-in" data-image='${JSON.stringify(image)}'>
                <div class="gallery-image-container">
                    <img 
                        src="${image.image}" 
                        alt="${image.title}"
                        class="gallery-image"
                        loading="lazy"
                    >
                </div>
                <div class="gallery-item-info">
                    <h3 class="image-title">${image.title}</h3>
                    ${image.author ? `<p class="image-author">Par ${image.author}</p>` : ''}
                    ${
                      image.tags
                        ? `
                        <div class="image-tags">
                            ${image.tags
                              .map(
                                (tag) => `
                                <span class="image-tag">${tag}</span>
                            `
                              )
                              .join('')}
                        </div>
                    `
                        : ''
                    }
                </div>
            </article>
      `
      )
      .join('');

    // Ajouter les événements de clic
    this.grid.querySelectorAll('.gallery-item').forEach((item) => {
      item.addEventListener('click', () => {
        const imageData = JSON.parse(item.dataset.image);
        this.modal.show(imageData, imagesArray);
      });
    });
  }

  showLoading() {
    this.container.innerHTML = `
            <div class="loading-state">
                <div class="loading-spinner"></div>
                <p>Chargement des dernières images...</p>
            </div>
        `;
  }

  showError() {
    this.grid.innerHTML = `
            <div class="error-state">
                <p>Une erreur est survenue lors du chargement des images.</p>
                <button onclick="location.reload()">Réessayer</button>
            </div>
        `;
  }
}
