// src/js/main.js
import { Autocomplete } from './components/AutoComplete.js';
import { initializeForms } from './components/forms';
// import { ImageUploader } from './components/ImageUploader.js';
import { initializeNavigation } from './components/navigation';
import { initializeThemeToggle } from './components/themeToggle';
import { renderHomePage } from './pages/Home.js';
import { Router } from './router/router.js';
import { KeyboardShortcutManager } from './utils/KeyboardShortcuts.js';

// Initialisation au chargement du DOM
// Point d'entrée de l'application
document.addEventListener('DOMContentLoaded', () => {
  initializeThemeToggle();
  initializeNavigation();
  initializeForms();
  // Initialisation de la page
  renderHomePage();
  // Initialisation de l'autocomplétion
  new Autocomplete();
  // Initialisation des raccourcis clavier
  new KeyboardShortcutManager();
  // Initialisation de l'upload d'image
  // new ImageUploader();
  // Initialisation du router
  new Router();
});

// Gestion du chargement asynchrone
window.addEventListener('load', () => {
  document.body.classList.add('loaded');
});

// Gestion du scroll
let lastScroll = 0;
window.addEventListener('scroll', () => {
  const currentScroll = window.pageYOffset;
  const header = document.querySelector('.main-header');

  if (currentScroll > lastScroll && currentScroll > 100) {
    header?.classList.add('header-hidden');
  } else {
    header?.classList.remove('header-hidden');
  }
  lastScroll = currentScroll;
});
