// src/js/pages/Upload.js
import { createFooter } from '../components/Footer.js';
import { createHeader } from '../components/Header.js';
import { ImageUploader } from '../components/ImageUploader.js';

export const renderUploadPage = () => {
  const app = document.getElementById('app');

  // Structure de la page
  app.innerHTML = `
        ${createHeader()}
        <main class="upload-page">
            <div class="container">
                <div class="page-header">
                    <h1>Téléverser des images</h1>
                    <p>Partagez vos plus belles photos avec la communauté</p>
                </div>
                
                <section class="upload-section" id="upload-section">
                    <!-- Le contenu sera injecté par ImageUploader -->
                </section>
            </div>
        </main>
        ${createFooter()}
    `;

  // Initialisation de l'uploader avec le conteneur
  const uploadSelection = document.getElementById('upload-section');
  if (uploadSelection) {
    new ImageUploader(uploadSelection);
  }
};
