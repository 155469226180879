// src/js/components/CategoryGrid.js
export class CategoryGrid {
  constructor(container) {
    this.container = container;
    this.viewMode = localStorage.getItem('categoriesViewMode') || 'grid'; // Sauvegarde la préférence
    this.init();
  }

  async init() {
    try {
      await this.loadTags();
      this.render();
    } catch (error) {
      console.error('Erreur lors du chargement des tags:', error);
      this.showError();
    }
  }

  async loadTags() {
    try {
      const response = await fetch('https://restapi.fr/api/ingallery');
      const images = await response.json();
      const imagesArray = Array.isArray(images) ? images : [images];

      // Extraire tous les tags uniques
      const tagsMap = new Map();
      imagesArray.forEach((image) => {
        if (image.tags && Array.isArray(image.tags)) {
          image.tags.forEach((tag) => {
            const count = tagsMap.get(tag) || 0;
            tagsMap.set(tag, count + 1);
          });
        }
      });

      // Convertir en tableau et trier par nombre d'occurrences
      this.tags = Array.from(tagsMap.entries())
        .map(([name, count]) => ({ name, count }))
        .sort((a, b) => b.count - a.count);
    } catch (error) {
      console.error('Erreur:', error);
      this.tags = [];
    }
  }

  render() {
    if (this.tags.length === 0) {
      this.container.innerHTML = `
                <div class="no-tags">
                    <p>Aucun tag trouvé</p>
                </div>
            `;
      return;
    }

    this.container.innerHTML = `
            <div class="categories-header">
                <div class="view-toggles">
                    <button class="view-toggle ${this.viewMode === 'grid' ? 'active' : ''}" data-view="grid">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
                            <rect x="3" y="3" width="7" height="7"></rect>
                            <rect x="14" y="3" width="7" height="7"></rect>
                            <rect x="14" y="14" width="7" height="7"></rect>
                            <rect x="3" y="14" width="7" height="7"></rect>
                        </svg>
                    </button>
                    <button class="view-toggle ${this.viewMode === 'list' ? 'active' : ''}" data-view="list">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
                            <line x1="3" y1="6" x2="21" y2="6"></line>
                            <line x1="3" y1="12" x2="21" y2="12"></line>
                            <line x1="3" y1="18" x2="21" y2="18"></line>
                        </svg>
                    </button>
                </div>
            </div>
            <div class="categories-container ${this.viewMode}">
                ${this.tags
                  .map(
                    (tag) => `
                    <a href="/explorer?tag=${encodeURIComponent(tag.name)}" 
                       class="category-card" 
                       data-tag="${tag.name}">
                        <div class="category-content">
                            <h3 class="category-name">${tag.name}</h3>
                            <span class="category-count">${tag.count} image${tag.count > 1 ? 's' : ''}</span>
                        </div>
                    </a>
                `
                  )
                  .join('')}
            </div>
        `;

    // Ajouter les événements
    this.bindEvents();
  }

  bindEvents() {
    // Événements pour les boutons de vue
    const viewToggles = this.container.querySelectorAll('.view-toggle');
    viewToggles.forEach((toggle) => {
      toggle.addEventListener('click', () => {
        const newViewMode = toggle.dataset.view;
        this.changeViewMode(newViewMode);
      });
    });

    // Ajouter les événements de clic
    this.container.querySelectorAll('.category-card').forEach((card) => {
      card.addEventListener('click', (e) => {
        e.preventDefault();
        const tag = card.dataset.tag;
        window.history.pushState(
          {},
          '',
          `/explorer?tag=${encodeURIComponent(tag)}`
        );
        window.dispatchEvent(new PopStateEvent('popstate'));
      });
    });
  }

  changeViewMode(mode) {
    this.viewMode = mode;
    localStorage.setItem('categoriesViewMode', mode);
    this.render();
  }

  showError() {
    this.container.innerHTML = `
            <div class="error-message">
                <p>Une erreur est survenue lors du chargement des tags.</p>
                <button onclick="location.reload()" class="retry-button">Réessayer</button>
            </div>
        `;
  }
}
