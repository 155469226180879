// src/js/components/ImageModal.js
export class ImageModal {
  constructor() {
    this.createModal();
    this.bindEvents();
    this.currentIndex = 0;
    this.images = [];
    this.interfaceVisible = true; // État de visibilité de l'interface

    this.currentZoom = 1;
    this.isDragging = false;
    this.startPos = { x: 0, y: 0 };
    this.currentPos = { x: 0, y: 0 };
  }

  createModal() {
    const modal = document.createElement('div');
    modal.className = 'image-modal';
    modal.innerHTML = `
            <div class="modal-overlay"></div>
            <div class="modal-content">
                <button class="modal-close">&times;</button>
                <button class="modal-nav modal-prev">
                    <span class="nav-icon">&#10094;</span>
                </button>
                
                <button class="modal-nav modal-next">
                    <span class="nav-icon">&#10095;</span>
                </button>
                
                <div class="modal-image-container">
                    <img src="" alt="" class="modal-image">
                    
                    <!-- Contrôles de zoom -->
                    <div class="zoom-controls">
                        <button class="zoom-button zoom-in" title="Zoom avant">+</button>
                        <button class="zoom-button zoom-out" title="Zoom arrière">-</button>
                        <button class="zoom-button zoom-reset" title="Réinitialiser">Reset</button>
                    </div>
                </div>

                <div class="modal-info">
                    <h3 class="modal-title"></h3>
                    <p class="modal-author"></p>
                    <div class="modal-tags"></div>
                    <div class="modal-counter"></div>
                </div>
            </div>
        `;
    document.body.appendChild(modal);
    this.modal = modal;

    // Mettre à jour le zoom actuel
    this.zoomReset = this.modal.querySelector('.zoom-reset');
  }

  bindEvents() {
    // Fermer avec le bouton
    this.modal.querySelector('.modal-close').addEventListener('click', () => {
      this.hide();
    });

    // Fermer avec l'overlay
    this.modal.querySelector('.modal-overlay').addEventListener('click', () => {
      this.hide();
    });

    // Navigation
    // Suivant
    this.modal
      .querySelector('.modal-prev')
      .addEventListener('click', () => this.showPrevious());

    // Précédent
    this.modal
      .querySelector('.modal-next')
      .addEventListener('click', () => this.showNext());

    // Events de zoom
    const image = this.modal.querySelector('.modal-image');
    const container = this.modal.querySelector('.modal-image-container');

    // Boutons de zoom
    const zoomIn = this.modal.querySelector('.zoom-in');
    zoomIn.addEventListener('click', () => {
      console.log('Zoom in');
      this.zoom(0.2); // Zoom de 20%
    });

    const zoomOut = this.modal.querySelector('.zoom-out');
    zoomOut.addEventListener('click', () => {
      console.log('Zoom out');
      this.zoom(-0.2); // Zoom de -20%
    });

    const zoomReset = this.modal.querySelector('.zoom-reset');
    zoomReset.addEventListener('click', () => {
      console.log('Reset zoom');
      this.resetZoom(); // Réinitialiser le zoom
    });

    // Gérer le déplacement de l'image
    let isDragging = false;
    let startPos = { x: 0, y: 0 };

    // Zoom avec la molette de souris
    container.addEventListener('wheel', (e) => {
      e.preventDefault();
      const delta = e.deltaY * -0.01;
      this.zoom(delta);
    });

    // Déplacement de l'image
    image.addEventListener('mousedown', (e) => {
      if (this.currentZoom > 1) {
        isDragging = true;
        startPos = {
          x: e.clientX - this.currentPos.x,
          y: e.clientY - this.currentPos.y,
        };
      }
      //   this.startDrag(e);
    });

    document.addEventListener('mousemove', (e) => {
      if (!isDragging) return;

      this.currentPos = {
        x: e.clientX - startPos.x,
        y: e.clientY - startPos.y,
      };
      this.updateImageTransform();
      //   this.drag(e);
    });

    document.addEventListener('mouseup', () => {
      isDragging = false;
      //   this.stopDrag();
    });

    // Double-clic pour reset
    image.addEventListener('dblclick', () => {
      this.resetZoom();
    });

    // Gestion des raccourcis clavier
    document.addEventListener('keydown', (e) => {
      if (!this.modal.classList.contains('active')) return;

      switch (e.key) {
        case 'Escape':
          this.hide();
          break;
        case 'ArrowLeft':
          this.showPrevious();
          break;
        case 'ArrowRight':
          this.showNext();
          break;
        case 'h': // h pour hide/show (masquer/afficher)
        case 'i': // i pour interface
          this.toggleInterface();
          break;
      }
    });

    // Ajouter un bouton d'aide pour les raccourcis
    this.modal.querySelector('.modal-content').insertAdjacentHTML(
      'beforeend',
      `
            <button class="keyboard-help" title="Raccourcis clavier">
                ⌨️
            </button>
        `
    );

    this.modal.querySelector('.keyboard-help').addEventListener('click', () => {
      this.showKeyboardHelp();
    });
  }

  startDrag(e) {
    if (this.currentZoom > 1) {
      this.isDragging = true;
      const image = this.modal.querySelector('.modal-image');
      this.startPos = {
        x: e.clientX - this.currentPos.x,
        y: e.clientY - this.currentPos.y,
      };
    }
  }

  drag(e) {
    if (!this.isDragging) return;

    const image = this.modal.querySelector('.modal-image');
    this.currentPos = {
      x: e.clientX - this.startPos.x,
      y: e.clientY - this.startPos.y,
    };

    image.style.transform = `translate(${this.currentPos.x}px, ${this.currentPos.y}px) scale(${this.currentZoom})`;
  }

  stopDrag() {
    this.isDragging = false;
  }

  zoom(delta) {
    const newZoom = Math.max(1, Math.min(4, this.currentZoom + delta));
    if (newZoom !== this.currentZoom) {
      this.currentZoom = newZoom;
      this.updateImageTransform();
      this.updateZoomDisplay();
      const image = this.modal.querySelector('.modal-image');
      image.style.transform = `translate(${this.currentPos.x}px, ${this.currentPos.y}px) scale(${this.currentZoom})`;

      // Mettre à jour l'affichage du zoom
      if (this.zoomReset) {
        this.zoomReset.textContent = `${Math.round(this.currentZoom * 100)}%`;
      }

      // Ajouter/retirer la classe zoomed
      if (this.currentZoom > 1) {
        image.classList.add('zoomed');
      } else {
        image.classList.remove('zoomed');
      }
    }
  }

  resetZoom() {
    this.currentZoom = 1;
    this.currentPos = { x: 0, y: 0 };
    this.updateImageTransform();
    this.updateZoomDisplay();
    const image = this.modal.querySelector('.modal-image');
    image.style.transform = 'translate(0, 0) scale(1)';
  }

  updateImageTransform() {
    const image = this.modal.querySelector('.modal-image');
    image.style.transform = `translate(${this.currentPos.x}px, ${this.currentPos.y}px) scale(${this.currentZoom})`;
  }

  updateZoomDisplay() {
    const zoomReset = this.modal.querySelector('.zoom-reset');
    zoomReset.textContent = `${Math.round(this.currentZoom * 100)}%`;
  }

  toggleInterface() {
    this.interfaceVisible = !this.interfaceVisible;
    this.modal
      .querySelector('.modal-content')
      .classList.toggle('hide-interface', !this.interfaceVisible);
  }

  showKeyboardHelp() {
    const helpDialog = document.createElement('div');
    helpDialog.className = 'keyboard-help-dialog';
    helpDialog.innerHTML = `
            <div class="help-content">
                <h3>Raccourcis clavier</h3>
                <ul class="shortcuts-list">
                    <li><kbd>←</kbd> Image précédente</li>
                    <li><kbd>→</kbd> Image suivante</li>
                    <li><kbd>H</kbd> ou <kbd>I</kbd> Masquer/Afficher l'interface</li>
                    <li><kbd>Esc</kbd> Fermer la modal</li>
                </ul>
                <button class="close-help">Fermer</button>
            </div>
        `;

    this.modal.appendChild(helpDialog);

    const closeBtn = helpDialog.querySelector('.close-help');
    closeBtn.addEventListener('click', () => helpDialog.remove());

    helpDialog.addEventListener('click', (e) => {
      if (e.target === helpDialog) helpDialog.remove();
    });
  }

  show(imageData, allImages) {
    this.images = allImages;
    this.currentIndex = this.images.findIndex(
      (img) => img.image === imageData.image
    );

    this.updateModalContent();

    // Afficher la modal
    this.modal.classList.add('active');
    document.body.style.overflow = 'hidden'; // Empêcher le défilement
    // Réinitialiser le zoom à l'ouverture
    this.resetZoom();
  }
  updateModalContent() {
    const currentImage = this.images[this.currentIndex];
    const modalImageContainer = this.modal.querySelector(
      '.modal-image-container'
    );
    const oldImage = modalImageContainer.querySelector('.modal-image');

    // Créer la nouvelle image
    const newImage = document.createElement('img');
    newImage.className = 'modal-image slide-in';
    newImage.src = currentImage.image;
    newImage.alt = currentImage.title;

    // Déterminer la direction de l'animation
    const direction = this.lastIndex < this.currentIndex ? 'next' : 'prev';
    newImage.classList.add(direction);

    // Animer la sortie de l'ancienne image si elle existe
    if (oldImage) {
      oldImage.classList.add(
        'slide-out',
        direction === 'next' ? 'prev' : 'next'
      );
      setTimeout(() => oldImage.remove(), 300); // Supprimer après l'animation
    }

    // Ajouter la nouvelle image
    modalImageContainer.appendChild(newImage);

    // Mettre à jour les autres informations
    // const modalImage = this.modal.querySelector('.modal-image');
    const modalTitle = this.modal.querySelector('.modal-title');
    const modalAuthor = this.modal.querySelector('.modal-author');
    const modalTags = this.modal.querySelector('.modal-tags');
    const modalCounter = this.modal.querySelector('.modal-counter');

    // Animer les informations
    [modalTitle, modalAuthor, modalTags].forEach((el) => {
      el.classList.add('fade-out');
      setTimeout(() => {
        if (el === modalTitle) modalTitle.textContent = currentImage.title;
        if (el === modalAuthor)
          modalAuthor.textContent = currentImage.author
            ? `Par ${currentImage.author}`
            : '';
        if (el === modalTags) {
          modalTags.innerHTML = currentImage.tags
            ? currentImage.tags
                .map((tag) => `<span class="modal-tag">${tag}</span>`)
                .join('')
            : '';
        }
        el.classList.remove('fade-out');
        el.classList.add('fade-in');
        setTimeout(() => el.classList.remove('fade-in'), 300);
      }, 150);
    });

    // Mettre à jour le contenu
    // modalImage.src = currentImage.image;
    // modalImage.alt = currentImage.title;
    // modalTitle.textContent = currentImage.title;
    // modalAuthor.textContent = currentImage.author
    //   ? `Par ${currentImage.author}`
    //   : '';

    if (currentImage.tags) {
      modalTags.innerHTML = currentImage.tags
        .map((tag) => `<span class="modal-tag">${tag}</span>`)
        .join('');
    }

    // Mettre à jour le compteur
    modalCounter.textContent = `${this.currentIndex + 1} / ${this.images.length}`;

    // Gérer la visibilité des boutons de navigation
    this.modal.querySelector('.modal-prev').style.display =
      this.currentIndex > 0 ? 'block' : 'none';
    this.modal.querySelector('.modal-next').style.display =
      this.currentIndex < this.images.length - 1 ? 'block' : 'none';

    // Sauvegarder l'index actuel pour la prochaine transition
    this.lastIndex = this.currentIndex;
  }

  showPrevious() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
      this.updateModalContent();
    }
  }

  showNext() {
    if (this.currentIndex < this.images.length - 1) {
      this.currentIndex++;
      this.updateModalContent();
    }
  }

  hide() {
    this.modal.classList.remove('active');
    document.body.style.overflow = ''; // Réactiver le défilement
  }
}
