// src/js/pages/Terms.js
import { createFooter } from '../components/Footer.js';
import { createHeader } from '../components/Header.js';

export const renderLegalNoticesPage = () => {
  const app = document.getElementById('app');

  app.innerHTML = `
        ${createHeader()}
        <main class="legal-page legal-notices-page">
            <div class="container">
                <div class="page-header">
                    <h1>Mentions Légales</h1>
                </div>
                <div class="legal-content">
                    <section>
                        <h2>1. Informations générales</h2>
                        <p>Conformément à l'article 6 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique, les informations suivantes sont fournies aux utilisateurs du site :</p>
                        <ul>
                            <li><strong>Propriétaire du site :</strong> InGallery</li>
                            <li><strong>Adresse :</strong> 1 rue Lautréamont, 65000 Tarbes</li>
                            <li><strong>Téléphone :</strong> Contacter IUT Tarbes</li>
                            <li><strong>Email :</strong> Email IUT Tarbes</li>
                            <li><strong>SIRET :</strong> Projet Étudiant</li>
                        </ul>
                    </section>

                    <section>
                        <h2>2. Licence d'utilisation</h2>
                        <p>En téléversant une image, vous :</p>
                        <ul>
                            <li>Certifiez posséder les droits sur l'image ou avoir l'autorisation de la partager</li>
                            <li>Accordez une licence non-exclusive pour afficher l'image sur notre plateforme</li>
                            <li>Conservez tous vos droits sur vos images</li>
                        </ul>
                    </section>

                    <section>
                        <h2>3. Contenu interdit</h2>
                        <p>Les types de contenus suivants sont strictement interdits :</p>
                        <ul>
                            <li>Contenu illégal ou violant les droits d'auteur</li>
                            <li>Contenu offensant ou inapproprié</li>
                            <li>Contenu malveillant ou trompeur</li>
                            <li>Contenu publicitaire non autorisé</li>
                        </ul>
                    </section>

                    <section>
                        <h2>4. Responsabilités des utilisateurs</h2>
                        <p>En tant qu'utilisateur, vous vous engagez à :</p>
                        <ul>
                            <li>Ne pas perturber le fonctionnement du site</li>
                            <li>Ne pas tenter d'accéder à des zones restreintes</li>
                            <li>Respecter les droits des autres utilisateurs</li>
                            <li>Fournir des informations exactes lors du téléversement</li>
                        </ul>
                    </section>

                    <section>
                        <h2>5. Modifications du service</h2>
                        <p>Nous nous réservons le droit de :</p>
                        <ul>
                            <li>Modifier ou interrompre le service à tout moment</li>
                            <li>Supprimer tout contenu violant ces conditions</li>
                            <li>Mettre à jour ces conditions d'utilisation</li>
                        </ul>
                    </section>

                    <section>
                        <h2>6. Limitation de responsabilité</h2>
                        <p>Nous ne pouvons être tenus responsables :</p>
                        <ul>
                            <li>Des contenus partagés par les utilisateurs</li>
                            <li>Des interruptions temporaires du service</li>
                            <li>Des dommages directs ou indirects liés à l'utilisation du service</li>
                        </ul>
                    </section>

                </div>
            </div>
        </main>
        ${createFooter()}
    `;
};
