// src/js/pages/Explorer.js
import { FilterSection } from '../components/FilterSection.js';
import { createFooter } from '../components/Footer.js';
import { createHeader } from '../components/Header.js';
import { ImageGallery } from '../components/ImageGallery.js';

export const renderExplorerPage = () => {
  const app = document.getElementById('app');

  app.innerHTML = `
        ${createHeader()}
        <main class="explorer-page">
            <div class="container">
                <!-- En-tête de la page -->
                <div class="page-header">
                    <h1>Explorer</h1>
                    <p>Découvrez notre collection d'images</p>
                </div>

                <!-- Section de recherche et filtres -->
                <div id="filter-section"></div>

                <!-- Grille d'images -->
                <div id="gallery-container"></div>
            </div>
        </main>
        ${createFooter()}
    `;

  // Initialiser les composants
  const filterContainer = document.getElementById('filter-section');
  const galleryContainer = document.getElementById('gallery-container');

  const gallery = new ImageGallery(galleryContainer);
  new FilterSection(filterContainer, (filters) => {
    gallery.updateFilters(filters);
  });
};
