// src/js/components/ImageGallery.js
import { ImageModal } from './ImageModal.js';

export class ImageGallery {
  constructor(container) {
    this.container = container;
    this.page = 1;
    this.limit = 20;
    this.apiEndpoint = 'https://restapi.fr/api/ingallery';

    this.filters = {
      search: '',
      sort: 'recent',
      category: 'all',
    };

    // Créer la grille dès le début
    this.container.innerHTML = `<div class="gallery-grid"></div>`;
    this.grid = this.container.querySelector('.gallery-grid');

    // Créer l'instance de la modal
    this.modal = new ImageModal();

    this.initializeGallery();
    // this.init();
  }

  initializeGallery() {
    // Réinitialiser le conteneur et créer la grille
    this.container.innerHTML = `<div class="gallery-grid"></div>`;
    this.grid = this.container.querySelector('.gallery-grid');
    this.loadImages(); // Charger les images initiales
    this.setupInfiniteScroll();
  }

  //   async init() {
  // try {
  //   await this.loadImages();
  //   this.setupInfiniteScroll();
  // } catch (error) {
  //   console.error('Erreur lors du chargement des images:', error);
  // }
  //   }

  async loadImages() {
    try {
      console.log('Chargement des images...', this.page); // Pour voir la page actuelle

      const skip = (this.page - 1) * this.limit; // Calcul du skip basé sur la page

      // Construction de l'URL avec les filtres
      let url = `${this.apiEndpoint}?limit=${this.limit}&skip=${skip}`;

      // Ajout du tri
      switch (this.filters.sort) {
        case 'recent':
          url += '&sort=createdAt:desc';
          break;
        case 'oldest':
          url += '&sort=createdAt:asc';
          break;
        case 'title':
          url += '&sort=title:asc';
          break;
        case 'title-desc':
          url += '&sort=title:desc';
          break;
      }

      // Ajout de la recherche
      if (this.filters.search) {
        url += `&title=${this.filters.search}`;
      }

      // Ajout de la catégorie
      if (this.filters.category !== 'all') {
        url += `&category=${this.filters.category}`;
      }

      console.log("URL de l'API:", url);

      const response = await fetch(url);
      const images = await response.json();

      // Vérifier si nous avons des images
      console.log('Images reçues:', images); // Pour voir ce que l'API renvoie

      if (images) {
        // Si images est un objet unique, le convertir en tableau
        const imagesArray = Array.isArray(images) ? images : [images];

        if (imagesArray.length > 0) {
          this.renderImages(imagesArray);
          return true; // Indique qu'il y a des images
        } else {
          if (this.page === 1) {
            this.showNoImages();
          }
          return false; // Indique qu'il n'y a plus d'images
        }
      }
    } catch (error) {
      console.error('Erreur de chargement:', error);
      this.showError();
      return false;
    }
  }

  updateFilters(newFilters) {
    this.filters = { ...this.filters, ...newFilters };
    this.page = 1; // Réinitialiser la pagination
    this.initializeGallery();
  }

  renderImages(images) {
    console.log('Rendu des images:', images); // Pour voir ce qui est passé au rendu

    if (images.length === 0) {
      this.showNoImages();
      return;
    }

    const imagesHTML = images
      .map(
        (image) => `
            <article class="gallery-item" data-image='${JSON.stringify(image)}'>
                <div class="gallery-image-container">
                    <img 
                        src="${image.image}" 
                        alt="${image.title}"
                        class="gallery-image"
                        loading="lazy"
                    >
                </div>
                <div class="gallery-item-info">
                    <h3 class="image-title">${image.title}</h3>
                    ${image.author ? `<p class="image-author">Par ${image.author}</p>` : ''}
                    ${
                      image.tags
                        ? `
                        <div class="image-tags">
                            ${image.tags
                              .map(
                                (tag) => `
                                <span class="image-tag">${tag}</span>
                            `
                              )
                              .join('')}
                        </div>
                    `
                        : ''
                    }
                </div>
            </article>
        `
      )
      .join('');

    if (this.page === 1) {
      //   this.container.innerHTML = `<div class="gallery-grid">${imagesHTML}</div>`;
      this.grid.innerHTML = imagesHTML;
    } else {
      //   this.container
      // .querySelector('.gallery-grid')
      // .insertAdjacentHTML('beforeend', imagesHTML);
      this.grid.insertAdjacentHTML('beforeend', imagesHTML);
    }

    // Ajouter les événements de clic pour la modal
    this.grid.querySelectorAll('.gallery-item').forEach((item) => {
      item.addEventListener('click', () => {
        const imageData = JSON.parse(item.dataset.image);
        // Récupérer toutes les images actuellement affichées
        const allImages = Array.from(
          this.grid.querySelectorAll('.gallery-item')
        ).map((item) => JSON.parse(item.dataset.image));
        this.modal.show(imageData, allImages);
      });
    });
  }

  showError() {
    this.grid.innerHTML = `
            <div class="error-message">
                <p>Une erreur est survenue lors du chargement des images.</p>
                <button onclick="location.reload()">Réessayer</button>
            </div>
        `;
  }

  showNoImages() {
    this.grid.innerHTML = `
            <div class="no-images-message">
                <p>Aucune image n'a été trouvée.</p>
            </div>
        `;
  }

  setupInfiniteScroll() {
    const observer = new IntersectionObserver(
      async (entries) => {
        const lastEntry = entries[0];
        if (lastEntry.isIntersecting) {
          // Temporairement déconnecter l'observer pendant le chargement
          observer.unobserve(lastEntry.target);
          this.page++;
          const hasMoreImages = await this.loadImages();
          //   this.loadImages();

          if (hasMoreImages) {
            // Reconnecter l'observer si on a plus d'images
            observer.observe(lastEntry.target);
          }
        }
      },
      {
        rootMargin: '100px',
      }
    );

    // Observer le dernier élément
    const observerTarget = document.createElement('div');
    observerTarget.className = 'observer-target';
    this.container.appendChild(observerTarget);
    observer.observe(observerTarget);
  }
}
