// src/js/pages/Terms.js
import { createFooter } from '../components/Footer.js';
import { createHeader } from '../components/Header.js';

export const renderAboutPage = () => {
  const app = document.getElementById('app');

  app.innerHTML = `
        ${createHeader()}
        <main class="legal-page about-page">
            <div class="container">
                <div class="page-header">
                    <h1>À propos de InGallery</h1>
                </div>
                <div class="legal-content">
                    <section>
                        <h2>Notre Site</h2>
                        <p>InGallery est une plateforme dédiée au partage et à la
          découverte de photographies exceptionnelles. Lancé en septembre 2024,
          notre site vise à offrir une expérience visuelle unique, inspirée par
          la mise en page de Pinterest, tout en restant simple et efficace.</p>
                    </section>

                    <section>
                        <h2>Liens utiles</h2>
                        <p>En téléversant une image, vous :</p>
                        <ul>
                            <li>
            <a href="https://github.com/Eth3ri0n/InGallery.git"
              >Notre code source sur GitHub</a
            >
          </li>
                        </ul>
                    </section>

                    <section>
                        <h2>Notre équipe de développement</h2>
                        <h3>Développeurs principaux</h3>
                        <ul>
                            <li>RANDRIANARISAINA Thierryno - Développeur Front-end | Product Owner</li>
          <li>YANG Kexin - Développeuse Front-end | Scrum Master</li>
          <li>YANG Kexin | RANDRIANARISAINA Thierryno - Designer UI|UX</li>
                        </ul>
                    </section>

                    <section>
                        <h2>Processus de développement</h2>
                        <p>En tant qu'utilisateur, vous vous engagez à :</p>
                        <ol>
          <li>Conception et design</li>
          <li>Développement du front-end</li>
          <li>Tests et débogage</li>
          <li>Déploiement</li>
        </ol>
                    </section>

                    <section>
                        <h2>Technologies utilisées</h2>
                        <p>Nous nous réservons le droit de :</p>
                        <dl>
          <dt>HTML5</dt>
          <dd>Pour la structure de base du site</dd>
          <dt>CSS3</dt>
          <dd>Pour le style et la mise en page responsive</dd>
          <dt>JavaScript</dt>
          <dd>Pour la dynamique et l'interactivité du site</dd>
        </dl>
                    </section>

                    <section>
                        <h2>Tableau des fonctionnalités</h2>
                        <table>
          <thead>
            <tr>
              <th>Fonctionnalité</th>
              <th>Statut</th>
              <th>Développeur responsable</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Galerie d'images</td>
              <td>Terminer</td>
              <td>YANG Kexin | RANDRIANARISAINA Thierryno</td>
            </tr>
            <tr>
              <td>Mise en page</td>
              <td>Terminer</td>
              <td>YANG Kexin | RANDRIANARISAINA Thierryno</td>
            </tr>
            <tr>
              <td>Système de tags</td>
              <td>Terminer</td>
              <td>YANG Kexin | RANDRIANARISAINA Thierryno</td>
            </tr>
          </tbody>
        </table>
                    </section>
                    <section>
                        <h2>Maquette</h2>
        <div id="octopus">
          <iframe
            title="Octopus.do visual sitemap"
            width="100%"
            height="500"
            src="https://share.octopus.do/embed/mrijpftxcsj"
          ></iframe>
        </div>
        <p>Téléchargez notre maquette :</p>
        <a href="/pdf/InGallery.pdf" download
          >Maquette du site (PDF)</a
        >
                    </section>

                </div>
            </div>
        </main>
        ${createFooter()}
    `;
};
