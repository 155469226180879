// src/js/pages/Terms.js
import { createFooter } from '../components/Footer.js';
import { createHeader } from '../components/Header.js';

export const renderFaqPage = () => {
  const app = document.getElementById('app');

  app.innerHTML = `
        ${createHeader()}
        <main class="legal-page faq-page">
            <div class="container">
            <div class="page-header">
                <h1>FAQ</h1>
                <p>Bienvenue dans notre FAQ. Vous trouverez ici les réponses aux questions les plus fréquentes concernant notre galerie d'images.</p>
            </div>

            <!-- Questions et réponses -->
            <section class="faq-content">
                <article>
                    <h2>Qu'est-ce que cette galerie d'images ?</h2>
                    <p>Notre galerie d'images est une plateforme inspirée du style Pinterest, permettant aux utilisateurs de découvrir, sauvegarder et partager des images dans différents domaines comme l'art, le design, et bien plus encore.</p>
                </article>

                <article>
                    <h2>Comment puis-je créer un compte ?</h2>
                    <p>Actuellement, notre galerie ne nécessite pas de compte utilisateur. Vous pouvez explorer librement toutes les images disponibles.</p>
                </article>

                <article>
                    <h2>Puis-je télécharger les images ?</h2>
                    <p>Oui, les images peuvent être téléchargées si elles sont marquées comme libres de droits. Recherchez l'icône de téléchargement sur chaque image.</p>
                </article>

                <article>
                    <h2>Puis-je contribuer à la galerie avec mes propres images ?</h2>
                    <p>Cette fonctionnalité est en cours de développement. Revenez bientôt pour plus d'informations.</p>
                </article>

                <article>
                    <h2>Quels types d'images puis-je trouver ici ?</h2>
                    <p>Vous trouverez une variété d'images allant des photographies artistiques, des illustrations graphiques, des inspirations pour la décoration d'intérieur, et bien plus.</p>
                </article>

                <article>
                    <h2>Comment fonctionne la recherche d'images ?</h2>
                    <p>Utilisez la barre de recherche située en haut de la page pour trouver des images par mots-clés, thèmes ou couleurs. Notre système filtre automatiquement les résultats pour vous offrir les meilleures options.</p>
                </article>
            </section>

            <!-- Aide supplémentaire -->
            <section class="faq-help">
                <h2>Vous avez une autre question ?</h2>
                <p>Si votre question ne figure pas dans cette FAQ, n'hésitez pas à nous contacter :</p>
                <form action="/submit-question" method="POST">
                    <label for="email">Votre email :</label>
                    <input type="email" id="email" name="email" required>

                    <label for="question">Votre question :</label>
                    <textarea id="question" name="question" rows="5" required></textarea>

                    <button type="submit">Envoyer</button>
                </form>
            </section>

            </div>
        </main>
        ${createFooter()}
    `;
};
