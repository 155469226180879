// src/js/components/SearchSection.js
export const createSearchSection = () => {
  return `
        <section class="search-section">
            <div class="container">
                <div class="search-bar">
                    <input type="text" class="search-input" placeholder="Rechercher des images...">
                    <button class="search-button">Rechercher</button>
                </div>
                <div class="filter-section">
                    <!-- Filtres -->
                </div>
            </div>
        </section>
    `;
};
