// src/js/pages/Privacy.js
import { createFooter } from '../components/Footer.js';
import { createHeader } from '../components/Header.js';

export const renderPrivacyPage = () => {
  const app = document.getElementById('app');

  app.innerHTML = `
        ${createHeader()}
        <main class="legal-page privacy-page">
            <div class="container">
                <div class="page-header">
                    <h1>Politique de confidentialité</h1>
                </div>
                <div class="legal-content">
                    <section>
                        <h2>1. Collecte des informations</h2>
                        <p>Nous collectons les informations suivantes :</p>
                        <ul>
                            <li>Noms et contenus des images téléversées</li>
                            <li>Tags associés aux images</li>
                            <li>Noms des auteurs fournis volontairement</li>
                        </ul>
                    </section>

                    <section>
                        <h2>2. Utilisation des informations</h2>
                        <p>Les informations collectées sont utilisées pour :</p>
                        <ul>
                            <li>Afficher et organiser les images dans la galerie</li>
                            <li>Permettre la recherche et le filtrage des images</li>
                            <li>Attribuer correctement les crédits aux auteurs</li>
                        </ul>
                    </section>

                    <section>
                        <h2>3. Protection des données</h2>
                        <p>Vos données sont protégées par les mesures suivantes :</p>
                        <ul>
                            <li>Stockage sécurisé des données</li>
                            <li>Accès limité aux informations personnelles</li>
                            <li>Chiffrement des données sensibles</li>
                        </ul>
                    </section>

                    <section>
                        <h2>4. Droits des utilisateurs</h2>
                        <p>Vous disposez des droits suivants :</p>
                        <ul>
                            <li>Droit d'accès à vos données</li>
                            <li>Droit de rectification</li>
                            <li>Droit à l'effacement</li>
                            <li>Droit d'opposition au traitement</li>
                        </ul>
                    </section>

                    <section>
                        <h2>5. Contact</h2>
                        <p>Pour toute question concernant vos données personnelles, vous pouvez :</p>
                        <ul>
                            <li>Utiliser notre formulaire de contact</li>
                            <li>Nous envoyer un email</li>
                            <li>Nous contacter par courrier</li>
                        </ul>
                    </section>
                </div>
            </div>
        </main>
        ${createFooter()}
    `;
};
