// src/js/components/Header.js
export const createHeader = () => {
  return `
        <header class="main-header">
            <div class="header-container">
                <a href="/" class="logo" data-route="/">
                    <span class="logo-text">ImageGallery</span>
                </a>

                <nav class="main-nav">
                    <ul class="nav-list">
                        <li class="nav-item">
                            <a href="/" class="nav-link active" data-route="/">Accueil</a>
                        </li>
                        <li class="nav-item">
                            <a href="/explorer" class="nav-link" data-route="/explorer">Explorer</a>
                        </li>
                        <li class="nav-item">
                            <a href="/categories" class="nav-link" data-route="/categories">Catégories</a>
                        </li>
                    </ul>
                </nav>

                <div class="header-actions">
                    <button class="upload-btn" data-route="/upload">
                        <span class="upload-icon">+</span>
                        <span class="upload-text">Upload</span>
                    </button>
                    
                    <button class="theme-toggle" aria-label="Changer de thème">
                        <div class="theme-toggle-track">
                            <div class="theme-toggle-circle"></div>
                        </div>
                    </button>
                </div>
            </div>
        </header>
    `;
};
