// src/js/components/navigation.js
export const initializeNavigation = () => {
  const mobileMenuToggle = document.querySelector('.mobile-menu-toggle');
  const mainNav = document.querySelector('.main-nav');

  mobileMenuToggle?.addEventListener('click', () => {
    mainNav?.classList.toggle('active');
    document.body.classList.toggle('nav-open');
  });

  // Ferme le menu au clic sur un lien
  const navLinks = document.querySelectorAll('.nav-link');
  navLinks.forEach((link) => {
    link.addEventListener('click', () => {
      mainNav?.classList.remove('active');
      document.body.classList.remove('nav-open');
    });
  });
};
