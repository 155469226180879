// src/js/components/forms.js
export const initializeForms = () => {
  const forms = document.querySelectorAll('form');

  forms.forEach((form) => {
    form.addEventListener('submit', async (e) => {
      e.preventDefault();

      const formData = new FormData(form);
      const formObject = Object.fromEntries(formData.entries());

      try {
        // Simulation d'envoi de données
        console.log('Données du formulaire:', formObject);

        // Ajoutez ici votre logique d'envoi de formulaire

        form.reset();
        showNotification('Message envoyé avec succès !', 'success');
      } catch (error) {
        console.error('Erreur:', error);
        showNotification('Une erreur est survenue.', 'error');
      }
    });
  });
};
