// src/js/router/router.js
import { renderAboutPage } from '../pages/About.js';
import { renderCategoriesPage } from '../pages/Categories.js';
import { renderContactPage } from '../pages/Contact.js';
import { renderCookiesPage } from '../pages/Cookies.js';
import { renderExplorerPage } from '../pages/Explorer.js';
import { renderFaqPage } from '../pages/Faq.js';
import { renderHomePage } from '../pages/Home.js';
import { renderLegalNoticesPage } from '../pages/LegalNotices.js';
import { renderLicensesPage } from '../pages/Licenses.js';
import { renderPrivacyPage } from '../pages/Privacy.js';
import { renderTermsPage } from '../pages/Terms.js';
import { renderUploadPage } from '../pages/Upload.js';

export class Router {
  constructor() {
    this.routes = {
      '/': renderHomePage,
      '/explorer': renderExplorerPage,
      '/categories': renderCategoriesPage,
      '/upload': renderUploadPage,
      '/privacy': renderPrivacyPage,
      '/terms': renderTermsPage,
      '/cookies': renderCookiesPage,
      '/licenses': renderLicensesPage,
      '/legal-notices': renderLegalNoticesPage,
      '/about': renderAboutPage,
      '/faq': renderFaqPage,
      '/contact': renderContactPage,
    };

    // Gestion du bouton retour du navigateur
    window.addEventListener('popstate', () => this.handleRoute());
    // Vous devrez implémenter cette fonction dans votre routeur
    // window.dispatchEvent(new PopStateEvent('popstate'));

    // Gestion des clics sur les liens
    document.addEventListener('click', (e) => {
      const routeElement = e.target.closest('[data-route]');
      if (routeElement) {
        e.preventDefault();
        this.navigateTo(routeElement.getAttribute('data-route'));
      }
    });

    // Route initiale
    this.handleRoute();
  }

  handleRoute() {
    const path = window.location.pathname;
    const renderFunction = this.routes[path] || this.routes['/'];
    renderFunction();
  }

  navigateTo(route) {
    window.history.pushState({}, '', route);
    this.handleRoute();
  }
}
