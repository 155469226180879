// src/js/pages/Licenses.js
import { createFooter } from '../components/Footer.js';
import { createHeader } from '../components/Header.js';

export const renderLicensesPage = () => {
  const app = document.getElementById('app');

  app.innerHTML = `
        ${createHeader()}
        <main class="legal-page">
            <div class="container">
                <div class="page-header">
                    <h1>Licences</h1>
                </div>
                <div class="legal-content">
                    <section>
                        <h2>1. Licence des images</h2>
                        <p>Les images publiées sur notre plateforme sont soumises aux conditions suivantes :</p>
                        <ul>
                            <li>Les auteurs conservent leurs droits sur leurs images</li>
                            <li>Une licence non-exclusive est accordée à la plateforme pour l'affichage</li>
                            <li>Les utilisateurs peuvent visualiser les images mais ne peuvent pas les réutiliser sans autorisation</li>
                        </ul>
                    </section>

                    <section>
                        <h2>2. Droits des utilisateurs</h2>
                        <p>En tant qu'utilisateur, vous pouvez :</p>
                        <ul>
                            <li>Visualiser les images sur la plateforme</li>
                            <li>Partager les liens vers les images</li>
                            <li>Télécharger les images pour un usage personnel</li>
                            <li>Contacter les auteurs pour demander des autorisations supplémentaires</li>
                        </ul>
                    </section>

                    <section>
                        <h2>3. Restrictions d'utilisation</h2>
                        <p>Il est interdit de :</p>
                        <ul>
                            <li>Utiliser les images à des fins commerciales sans autorisation</li>
                            <li>Modifier ou altérer les images sans permission</li>
                            <li>Retirer les attributions ou crédits des images</li>
                            <li>Redistribuer les images sur d'autres plateformes</li>
                        </ul>
                    </section>

                    <section>
                        <h2>4. Attribution</h2>
                        <p>Lorsque l'utilisation est autorisée, vous devez :</p>
                        <ul>
                            <li>Mentionner le nom de l'auteur</li>
                            <li>Inclure un lien vers l'image originale</li>
                            <li>Indiquer si des modifications ont été apportées</li>
                            <li>Préciser que l'image provient de notre plateforme</li>
                        </ul>
                    </section>

                    <section>
                        <h2>5. Licences tierces</h2>
                        <p>Notre plateforme utilise les composants open source suivants :</p>
                        <div class="license-list">
                            <div class="license-item">
                                <h4>Vite</h4>
                                <p>Licence MIT</p>
                            </div>
                            <div class="license-item">
                                <h4>SASS</h4>
                                <p>Licence MIT</p>
                            </div>
                        </div>
                    </section>

                    <section>
                        <h2>6. Signalement</h2>
                        <p>Si vous pensez qu'une image viole vos droits d'auteur :</p>
                        <ul>
                            <li>Utilisez le formulaire de contact</li>
                            <li>Fournissez des preuves de vos droits</li>
                            <li>L'image sera retirée pendant l'examen de votre demande</li>
                        </ul>
                    </section>
                </div>
            </div>
        </main>
        ${createFooter()}
    `;
};
