// src/js/pages/Cookies.js
import { createFooter } from '../components/Footer.js';
import { createHeader } from '../components/Header.js';

export const renderCookiesPage = () => {
  const app = document.getElementById('app');

  app.innerHTML = `
        ${createHeader()}
        <main class="legal-page">
            <div class="container">
                <div class="page-header">
                    <h1>Politique des Cookies</h1>
                </div>
                <div class="legal-content">
                    <section>
                        <h2>1. Qu'est-ce qu'un cookie ?</h2>
                        <p>Un cookie est un petit fichier texte stocké sur votre ordinateur ou appareil mobile lorsque vous visitez un site web. Les cookies sont largement utilisés pour faire fonctionner les sites web ou les rendre plus efficaces, ainsi que pour fournir des informations aux propriétaires du site.</p>
                    </section>

                    <section>
                        <h2>2. Comment utilisons-nous les cookies ?</h2>
                        <p>Nous utilisons les cookies pour :</p>
                        <ul>
                            <li>Mémoriser vos préférences de thème (clair/sombre)</li>
                            <li>Maintenir votre session de navigation</li>
                            <li>Améliorer les performances du site</li>
                            <li>Analyser l'utilisation du site</li>
                        </ul>
                    </section>

                    <section>
                        <h2>3. Types de cookies utilisés</h2>
                        <h3>Cookies essentiels</h3>
                        <p>Ces cookies sont nécessaires au fonctionnement du site. Ils incluent :</p>
                        <ul>
                            <li>Cookies de session</li>
                            <li>Cookies de préférences</li>
                        </ul>

                        <h3>Cookies analytiques</h3>
                        <p>Ces cookies nous permettent d'analyser l'utilisation du site pour l'améliorer :</p>
                        <ul>
                            <li>Statistiques de navigation</li>
                            <li>Performances du site</li>
                        </ul>
                    </section>

                    <section>
                        <h2>4. Gestion des cookies</h2>
                        <p>Vous pouvez contrôler et/ou supprimer les cookies comme vous le souhaitez :</p>
                        <ul>
                            <li>Paramètres de votre navigateur pour refuser tous les cookies</li>
                            <li>Suppression des cookies existants</li>
                            <li>Configuration pour être alerté lors de l'utilisation de cookies</li>
                        </ul>
                        <p>Notez que la désactivation des cookies peut affecter certaines fonctionnalités du site.</p>
                    </section>

                    <section>
                        <h2>5. Durée de conservation</h2>
                        <p>Les cookies que nous utilisons ont différentes durées de conservation :</p>
                        <ul>
                            <li>Cookies de session : supprimés à la fermeture du navigateur</li>
                            <li>Cookies persistants : maximum 13 mois</li>
                            <li>Cookies de préférences : conservés jusqu'à leur modification par l'utilisateur</li>
                        </ul>
                    </section>

                    <section>
                        <h2>6. Mise à jour de la politique</h2>
                        <p>Nous nous réservons le droit de modifier cette politique des cookies à tout moment. Les changements prendront effet dès leur publication sur le site. Nous vous encourageons à consulter régulièrement cette page pour rester informé des éventuelles modifications.</p>
                    </section>
                </div>
            </div>
        </main>
        ${createFooter()}
    `;
};
